import { websiteConstants } from "../../_constants/website.constants";

export function menu(state = null, action) {
  switch (action.type) {
    case websiteConstants.FETCH_MENU_ITEMS:
      return action.payload;
    case websiteConstants.ADD_OR_EDIT_MENU_ITEM:
      const exists = state.find((m) => m.id === action.payload.id);
      if (exists) {
        let { title, cmsPageId, order } = action.payload;
        return state.map((menuItem) =>
          menuItem.id === exists.id
            ? { ...menuItem, title, cmsPageId, order }
            : menuItem
        );
      } else return [...state, action.payload];

    case websiteConstants.DELETE_MENU_ITEM:
      let { id, order } = action.payload;

      let filtered = state.filter((item) => item.id !== id);

      filtered.forEach((item) => {
        if (item.order > order) item.order--;
      });

      return filtered;
    case websiteConstants.REORDER_MENU_ITEM:
      if (action.payload) {
        let modifiedState = state.map((menuItem) => {
          const exists = action.payload.find((m) => m.id === menuItem.id);
          return exists ? { ...menuItem, order: exists.order } : menuItem;
        });
        return modifiedState;
      }
      return state;
    default:
      return state;
  }
}
