export const commonConstants = {
  LOADING_PAGE: "LOADING_PAGE",

  GET_PAGING_LIST: "GET_PAGING_LIST",
  GET_STATUSES_LIST: "GET_STATUSES_LIST",
  GETROLES_SUCCESS: "GETROLES_SUCCESS",
  GET_COUNTRIES_LIST: "GET_COUNTRIES_LIST",
  GET_ATC_LIST: "GET_ATC_LIST",
  GET_DRUGNAMES_LIST: "GET_DRUGNAMES_LIST",
  GET_SUBSTANCE_LIST: "GET_SUBSTANCE_LIST",

  GET_KEYWORDTYPE_LIST: "GET_KEYWORDTYPE_LIST",

  GETLANGUAGES_SUCCESS: "KMS_GETLANGUAGES_SUCCESS",
  GETLANGUAGES_FAILURE: "KMS_GETLANGUAGES_FAILURE",
  GETPAGELANGUAGES_SUCCESS: "GETPAGELANGUAGES_SUCCESS",
  GETPAGELANGUAGES_FAILURE: "GETPAGELANGUAGES_FAILURE",
};

export const editorConfiguration = {
  toolbar: {
    items: [
      "undo", "redo", '|',
      "heading", '|',
      "bold", "italic", "link", '|',
      "bulletedList", "numberedList", "indent", "outdent"
    ]
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  link: {
    decorators: {
      defaultProtocol: 'http://',
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        defaultValue: true,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      }
    }
  }
};

export const editorConfiguration2 = {
  toolbar: { items: [...editorConfiguration.toolbar.items, "sourceEditing"] }
};

export const editorConfigurationWithTable = {
  toolbar: { items: [...editorConfiguration.toolbar.items, "insertTable"] }
};
