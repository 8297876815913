import { websiteConstants } from "../../_constants/website.constants";

export function pages(state = [], action) {
  switch (action.type) {
    case websiteConstants.FETCH_WEBSITE_PAGES:
      return action.payload.pages;
    case websiteConstants.ADD_WEBSITE_PAGE:
      return [...state, action.payload];
    case websiteConstants.DELETE_WEBSITE_PAGE:
      return state.filter((item) => item.id !== action.payload);
    default:
      return state;
  }
}
