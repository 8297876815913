import { indicationConstants } from "../_constants/indication.constants.js";

export function indicationTranslations(state = [], action) {
    switch (action.type) {
        case indicationConstants.SET_INDICATIONS_TRANSLATIONS: {
            return action.payload;
        }
        default:
            return [];
    }
}