import { commonConstants } from "../_constants";

export function common(state = {}, action) {
    switch (action.type) {
        case commonConstants.GET_PAGING_LIST:
            return { ...state, pagingList: action.payload };
        case commonConstants.GET_STATUSES_LIST:
            return { ...state, statuses: action.payload };
        case commonConstants.GET_COUNTRIES_LIST:
            return { ...state, countries: action.payload };
        case commonConstants.GET_ATC_LIST:
            return { ...state, atcs: action.payload };
        case commonConstants.GET_DRUGNAMES_LIST:
            return { ...state, drugnames: action.payload };
        case commonConstants.GET_SUBSTANCE_LIST:
            return { ...state, substances: action.payload };
        case commonConstants.LOADING_PAGE:
            return { ...state, loading: action.payload };
        case commonConstants.GET_KEYWORDTYPE_LIST:
            return { ...state, keywordTypes: action.payload };
        case commonConstants.GETLANGUAGES_SUCCESS:
            return { ...state, languages: action.payload };
        case commonConstants.GETLANGUAGES_FAILURE:
            return { ...state, error: action.error };
        case commonConstants.GETPAGELANGUAGES_SUCCESS:
            return { ...state, pagelanguages: action.payload };
        case commonConstants.GETPAGELANGUAGES_FAILURE:
            return { ...state, error: action.error };
        case commonConstants.GETROLES_SUCCESS:
            return { ...state, roles: action.payload };
        default:
            return state;
    }
}
