import jwt_decode from "jwt-decode";
import logout from "../../_actions/authentication/logout";
import TokenService from "../../_services/token.service";

export const checkauth = () => async (dispatch, getState) => {
  let { authentication } = getState();
  const refreshToken = TokenService.getLocalRefreshToken();
  if (
    (!refreshToken || jwt_decode(refreshToken).exp < Date.now() / 1000) &&
    authentication.loggedIn === true
  ) {
    if (window.location.pathname !== "/twofactorauthentication") {
      dispatch(logout({ show: true, error: true, message: "Logged out because of inactivity!" }));
      TokenService.removeUser();
    }
  }
};
