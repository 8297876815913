import { enableES5, produce } from "immer";
import { websiteConstants } from "../../_constants/website.constants";
import _ from "lodash";

enableES5();

const initialChangeObject = {
  layoutId: null,
  position: null,
  text: null,
  footerLogoId: null,
  link: null,
  type: null,
  logoPreview: null,
};

export const modifiedFooterColumns = produce((draft, action) => {
  switch (action.type) {
    case websiteConstants.MODIFY_FOOTER_LAYOUT_COLUMN_TEXT: {
      let { layoutId, position, text, type } = action.payload;
      const found = _.find(
        draft,
        (i) => i.layoutId === layoutId && i.position === position
      );

      if (found) found.text = text;
      else {
        draft.push({ ...initialChangeObject, layoutId, position, text, type });
      }

      return;
    }
    case websiteConstants.MODIFY_FOOTER_LAYOUT_COLUMN_LOGO: {
      let { layoutId, position, footerLogoId, logoPreview, type } =
        action.payload;

      const found = _.find(
        draft,
        (i) => i.layoutId === layoutId && i.position === position
      );

      if (found) {
        found.footerLogoId = footerLogoId;
        found.logoPreview = logoPreview;
      } else
        draft.push({
          ...initialChangeObject,
          layoutId,
          position,
          footerLogoId,
          logoPreview,
          type,
        });

      return;
    }
    case websiteConstants.FETCH_FOOTER_LAYOUTS: {
      draft = [];
      return;
    }
    default:
      return;
  }
}, []);
