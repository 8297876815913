import { userConstants } from "../../_constants";
import { history } from "../../_helpers";
import TokenService from "../../_services/token.service";
import { notification } from "../notifications.actions";

export default function logout({
  show = false,
  message = "Logout successful!",
  error = false,
}) {
  TokenService.removeUser();
  if (show) {
    if (error) notification.warning({ message: message, duration: 1000 });
    else notification.success({ message: message, duration: 1000 });
    setTimeout(() => history.replace("/login"), 500);
  }
  return { type: userConstants.LOGOUT, payload: {} };
}
