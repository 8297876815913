import React, { Component } from "react";
import { connect } from "react-redux";
import AppRouter from "./appRouter";
import CustomRouter from "../../assests/customRouter";
import Loading from "../content/loading";
import { history } from "../../_helpers";
import logout from "../../_actions/authentication/logout";
import "react-loading-skeleton/dist/skeleton.css";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "rc-datepicker/lib/style.css";
import { checkauth } from "../../_actions/authentication/checkauth";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    checkauth();
    //this.checkToken();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      checkauth();
      //this.checkToken();
    }
  }
  // checkToken() {
  //   const { user } = this.props;
  //   if (!user || !user.token || jwt_decode(user.token)?.exp < Date.now() / 1000) {
  //     this.props.logout({});
  //     localStorage.removeItem("user");
  //   }
  // }

  render() {
    return (
      <CustomRouter history={history}>
        <React.Suspense fallback={<Loading visible={false} />}>
          <AppRouter />
        </React.Suspense>
      </CustomRouter>
    );
  }
}
const mapStateToProps = (state) => {
  return { user: state.authentication.user };
};
const mapDispatchToProps = { logout };
export default connect(mapStateToProps, mapDispatchToProps)(App);
