import { useSelector } from "react-redux";

const roleList =
{
"Admin": 1,
"Publisher": 2,
"Viewer" : 3,
"Editor" : 4
}

const permissions = {
"apikey.view": [roleList["Admin"]],
"mc.view": [roleList["Admin"]],
"user.view": [roleList["Admin"]],
"user.edit": [roleList["Admin"]],
"user.delete": [roleList["Admin"]],
"language.view": [roleList["Admin"]],
"language.edit": [roleList["Admin"]],
"language.delete": [roleList["Admin"]],
"infocus.view": [roleList["Admin"]],
"infocus.edit": [roleList["Admin"]],
"infocus.delete": [roleList["Admin"]],
"drugs.view": [roleList["Admin"]],
"website.view": [roleList["Admin"]],
"website.edit": [roleList["Admin"]],
"website.delete": [roleList["Admin"]],
"kp.view": [roleList["Admin"], roleList["Publisher"], roleList["Viewer"], roleList["Editor"]],
"kp.edit": [roleList["Admin"], roleList["Editor"]],
"kp.publish": [roleList["Admin"],roleList["Publisher"]],
"kp.delete": [roleList["Admin"], roleList["Editor"]],
}
const usePermissions = (permissionName) => {

const userRole = useSelector((state) => state.authentication.user.roleId);
let hasPermissions = false;

hasPermissions = permissions[permissionName].includes(userRole);

return hasPermissions;

};
export default usePermissions;