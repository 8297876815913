import { pages } from "./pages.reducer";
import { paging } from "./paging.reducer";
import { menu } from "./menu.reducer";
import { footer } from "./footer.reducer";
import { modifiedFooterColumns } from "./modifiedFooterColumns.reducer";
import { modifiedFooterColumnsLinks } from "./modifiedFooterColumnsLogoLinks.reducer";

export function website(state = {}, action) {
  return {
    pages: pages(state.pages, action),
    paging: paging(state.paging, action),
    menu: menu(state.menu, action),
    footer: footer(state.footer, action),
    modifiedFooterColumns: modifiedFooterColumns(
      state.modifiedFooterColumns,
      action
    ),
    modifiedFooterColumnsLinks: modifiedFooterColumnsLinks(
      state.modifiedFooterColumnsLinks,
      action
    ),
  };
}
