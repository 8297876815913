import { userConstants } from "../_constants";

export function image(state = {}, action){
  switch (action.type){
    case userConstants.GETIMAGE_SUCCESS:
      return {
        image: action.image,
      };
    default:
      return state;
  }
}
export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
    case userConstants.GETALLSHORT_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.GETALLSHORT_SUCCESS:
      return {
        short: action.users,
      };
    case userConstants.GETALL_FAILURE:
    case userConstants.GETALLSHORT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
