import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
//import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";

//const loggerMiddleware = createLogger();
let middleware = [thunk]; //add middlewares in this array

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      ...middleware
      //, loggerMiddleware
    )
  )
);
