import { websiteConstants } from "../../_constants/website.constants";

const initialState = { pageSize: 10, page: 1, totalPages: 1 };

export function paging(state = initialState, action) {
  switch (action.type) {
    case websiteConstants.SEARCH_ACTIVE_PAGE:
      return { ...state, page: action.payload };
    case websiteConstants.SEARCH_PAGE_SIZE:
      return { ...state, page: 1, pageSize: action.payload };
    case websiteConstants.FETCH_WEBSITE_PAGES:
      return { ...state, totalPages: action.payload.totalPages };
    default:
      return state;
  }
}
