import { Store } from "react-notifications-component";

export const notification = {
  success,
  error,
  warning,
  info,
};

const defaultNotification = {
  insert: "top",
  container: "bottom-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
};

function success({ title = "Success", message, duration = 1500 }) {
  Store.addNotification({
    ...defaultNotification,
    title: title,
    message: message,
    type: "success",
    dismiss: { duration: duration },
  });
}

function error({ title = "Error", message, duration = 1500 }) {
  Store.addNotification({
    ...defaultNotification,
    title: title,
    message: message,
    type: "danger",
    dismiss: { duration: duration },
  });
}

function warning({ title = "Warning", message, duration = 1500 }) {
  Store.addNotification({
    ...defaultNotification,
    title: title,
    message: message,
    type: "warning",
    dismiss: { duration: duration },
  });
}

function info({ title = "Info", message, duration = 1500 }) {
  Store.addNotification({
    ...defaultNotification,
    title: title,
    message: message,
    type: "info",
    dismiss: { duration: duration },
  });
}
