import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   "Content-type": "application/json"
  // }
});
export default instance;

export const clearInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
