export const websiteConstants = {
  FETCH_WEBSITE_PAGES: "FETCH_WEBSITE_PAGES",
  DELETE_WEBSITE_PAGE: "DELETE_WEBSITE_PAGE",
  ADD_WEBSITE_PAGE: "ADD_WEBSITE_PAGE",
  SEARCH_ACTIVE_PAGE: "SEARCH_ACTIVE_PAGE",
  SEARCH_PAGE_SIZE: "SEARCH_PAGE_SIZE",
  FETCH_MENU_ITEMS: "FETCH_MENU_ITEMS",
  ADD_OR_EDIT_MENU_ITEM: "ADD_OR_EDIT_MENU_ITEM",
  DELETE_MENU_ITEM: "DELETE_MENU_ITEM",
  REORDER_MENU_ITEM: "REORDER_MENU_ITEM",
  FETCH_FOOTER_LAYOUTS: "FETCH_FOOTER_LAYOUTS",
  FETCH_ACTIVE_FOOTER: "FETCH_ACTIVE_FOOTER",
  MODIFY_FOOTER_LAYOUT_COLUMN_LOGO: "MODIFY_FOOTER_LAYOUT_COLUMN_LOGO",
  MODIFY_FOOTER_LAYOUT_COLUMN_LINK: "MODIFY_FOOTER_LAYOUT_COLUMN_LINK",
  MODIFY_FOOTER_LAYOUT_COLUMN_TEXT: "MODIFY_FOOTER_LAYOUT_COLUMN_TEXT",
};
