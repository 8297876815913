export const drugConstants = {
    GET_TABLE_DRUGS: 'GET_TABLE_DRUGS',
};


export const importDataConstants= {
    GET_IMPORT_INFO: 'GET_IMPORT_INFO',
    SET_IMPORT_STEP: 'SET_IMPORT_STEP',
    START_IMPORTING: 'START_IMPORTING',
}
