import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from 'i18next-http-backend';
import api from "./_helpers/mainapi.js";
import { initReactI18next } from 'react-i18next';

const loadResources = async (symbol) => {
  const response = await api.get("/pagelanguage/getallpagetranslationsforlanguagebysymbol", {
      params: { symbol: symbol }
  });
  return response.data;
}

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (_options, url, _payload, callback) => {
    try {
      const lng = url.split('|')[0];
        loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: "en",
    debug: false,
    load: "languageOnly",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
