import { userConstants } from "../_constants";


let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return { ...state, loggingIn: true, email: action.payload };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.payload,
      };
    case userConstants.LOGIN_FAILURE:
      return { ...state, loggedIn: false, loggingIn: false, user: null };
    case userConstants.LOGOUT:
      return { ...state, loggedIn: false, loggingIn: false, user: null };
    default:
      return state;
  }
}

