export const kmsConstants = {
    GETPAGES_REQUEST: 'KMS_GETPAGES_REQUEST',
    GETPAGES_SUCCESS: 'KMS_GETPAGES_SUCCESS',
    GETPAGES_FAILURE: 'KMS_GETPAGES_FAILURE',
    GETEDITPAGE_SUCCESS: 'KMS_GETEDITPAGE_SUCCESS',
    GETSEARCHPAGE_SUCCESS: 'KMS_GETSEARCHPAGE_SUCCESS',
    GETSEARCHVALUE_SUCCESS: 'KMS_GETSEARCHVALUE_SUCCESS',
  
    GETKMSID_SUCCESS: 'KMS_GETKMSID_SUCCESS',
    GETKMSID_FAILURE: 'KMS_GETKMSID_FAILURE',
    GETLANG_FAILURE: 'GETLANG_FAILURE',
    GETLANG_SUCCESS: 'GETLANG_SUCCESS',
    GETTRID_SUCCESS: 'GETTRID_SUCCESS',
    GETTRID_FAILURE: 'GETTRID_FAILURE',
};