import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { users, image } from "./users.reducer";
import { alert } from "./alert.reducer";
import { common } from "./common.reducer";
import { indicationTranslations } from "./indications.reducer";
import { drugs, importData } from "./drugs.reducer";
import { website } from "./website";
import {
    pages,
    kmsid,
    searchValue,
    searchPage,
    lang,
    trid
} from "./kms.reducer";

const rootReducer = combineReducers({
    authentication,
    image,
    users,
    alert,
    common,
    drugs,
    pages,
    kmsid,
    trid,
    lang,
    searchValue,
    searchPage,
    website,
    importData,
    indicationTranslations
});
export default rootReducer;


