import { websiteConstants } from "../../_constants/website.constants";

function activeFooter(state = null, action) {
  switch (action.type) {
    case websiteConstants.FETCH_ACTIVE_FOOTER:
      return action.payload;
    default:
      return state;
  }
}

function layouts(state = [], action) {
  switch (action.type) {
    case websiteConstants.FETCH_FOOTER_LAYOUTS:
      return action.payload;
    default:
      return state;
  }
}

export function footer(state = {}, action) {
  return {
    activeFooter: activeFooter(state.activeFooter, action),
    layouts: layouts(state.layouts, action),
  };
}
