import { kmsConstants } from "../_constants";

export function searchValue(state = "", action) {
  switch (action.type) {
    case kmsConstants.GETSEARCHVALUE_SUCCESS:
      return action.searchValue;
    default:
      return state;
  }
}

export function searchPage(state = 1, action) {
  switch (action.type) {
    case kmsConstants.GETSEARCHPAGE_SUCCESS:
      return action.searchPage;
    default:
      return state;
  }
}

export function kmsid(state = {}, action) {
  switch (action.type) {
    case kmsConstants.GETKMSID_SUCCESS:
      return action.kmsid;
    default:
      return state;
  }
}
export function trid(state = {}, action) {
  switch (action.type) {
    case kmsConstants.GETTRID_SUCCESS:
      return action.trid;
    default:
      return state;
  }
}
export function lang(state = {}, action) {
  switch (action.type) {
    case kmsConstants.GETLANG_SUCCESS:
      return action.lang;
    default:
      return state;
  }
}

export function pages(state = {}, action) {
  switch (action.type) {
    case kmsConstants.GETPAGES_REQUEST:
      return {
        loading: true,
      };
    case kmsConstants.GETPAGES_SUCCESS:
      return {
        items: action.payload,
      };
    case kmsConstants.GETPAGES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
