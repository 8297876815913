import { enableES5, produce } from "immer";
import { websiteConstants } from "../../_constants/website.constants";
import _ from "lodash";

enableES5();

export const modifiedFooterColumnsLinks = produce((draft, action) => {
  switch (action.type) {
    case websiteConstants.MODIFY_FOOTER_LAYOUT_COLUMN_LINK: {
      let { layoutId, position, link, type } = action.payload;
      const found = _.find(
        draft,
        (i) => i.layoutId === layoutId && i.position === position
      );

      if (found) found.link = link;
      else {
        draft.push({ layoutId, position, link, type });
      }
      return;
    }
    default:
      return;
  }
}, []);
