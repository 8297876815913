export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETALLSHORT_REQUEST: 'USERS_GETALLSHORT_REQUEST',
    GETALLSHORT_SUCCESS: 'USERS_GETALLSHORT_SUCCESS',
    GETALLSHORT_FAILURE: 'USERS_GETALLSHORT_FAILURE',

    GETIMAGE_SUCCESS: 'USERS_GETIMAGE_SUCCESS'
};
