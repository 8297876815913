
import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./privateRoute";
import { ProtectedRoute } from "./protectedRoute";


const BaseLayout = lazy(() => import("../../layout/BaseLayout"));
const AdminLayout = lazy(() => import("../../layout/AdminLayout"));
const Languages = lazy(() => import("../../pages/Languages"));
const AddTranslation = lazy(() => import("../../pages/AddTranslation"));

const Home = lazy(() => import("../../pages/Home"));
const SearchResult = lazy(() => import("../../pages/SearchResult"));
const DetailPage = lazy(() => import("../../pages/DetailPage"));
const Api = lazy(() => import("../../pages/Api"));
const Login = lazy(() => import("../../pages/Login"));
const ResetPassword = lazy(() => import("../../pages/ResetPassword"));
const Profile = lazy(() => import("../../pages/Profile"));
const ForgotPassword = lazy(() => import("../../pages/ForgotPassword"));
const Knowledge = lazy(() => import("../../pages/Knowledge"));
const ApiKey = lazy(() => import("../../pages/ApiKey"));
const MedicalConditions = lazy(() => import("../../pages/MedicalConditions"));
const AddUser = lazy(() => import("../../pages/AddUser"));
const InFocus = lazy(() => import("../../pages/InFocus"));
const CreateInFocus = lazy(() => import("../../pages/CreateInFocus"));
const DrugList = lazy(() => import("../../pages/DrugList"));
const Translations = lazy(() => import("../../pages/Translations"));
const Users = lazy(() => import("../../pages/Users"));
const Website = lazy(() => import("../../pages/Website"));
const WebSitePage = lazy(() => import("../../pages/WebSitePage"));
const PreviewKnowledge = lazy(() => import("../../pages/PreviewKnowledge"));
const TwoFactorAuthentication = lazy(() => import("../../pages/TwoFactorAuthentication"));
const EditKnowledgePageWrapper = lazy(() => import("../../pages/EditKnowledgePageWrapper"));
const MedicalConditionsPub = lazy(() => import("../../pages/MedicalConditionsPub"));
// const MedicalConditionsDetail = lazy(() => import("../../pages/MedicalConditionsDetail"));
const AboutUs = lazy(() => import("../../pages/AboutUs"));
// const HowWeWork = lazy(() => import("../../pages/HowWeWork"));
const CreateNewPage = lazy(() => import("../../pages/CreateNewPage"));
const InFocusDetail = lazy(() => import("../../pages/InFocusDetail"));
const NotFound = lazy(() => import("../content/notFound"));
const ErrorPage = lazy(() => import("../content/error"));

const AppRouter = () => {
    return (
        <Routes>
            <Route path="*" element={<NotFound />} />
            <Route element={<BaseLayout />}>
                <Route path="/notfound" element={<NotFound />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/" exact element={<Home />} />
                <Route path="/search-result" element={<SearchResult />}></Route>
                <Route path="/:kmstitle/" element={<DetailPage />}></Route>
                <Route path="/pages/:seourl" element={<WebSitePage />}></Route>
                <Route path="/about-us" element={<AboutUs />}></Route>
                <Route path="/infocusdetail/:title" element={<InFocusDetail />}></Route>
                <Route path="/maternal-medical-conditions" element={<MedicalConditionsPub />}></Route>
                {/* <Route path="/how-we-work" element={<HowWeWork />}></Route> */}
                {/* <Route path="/MedicalConditionsDetail" element={<MedicalConditionsDetail />} /> */}
            </Route>

            <Route path="/api" element={<Api />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/twofactorauthentication" element={<TwoFactorAuthentication />}></Route>
            <Route path="/resetpassword" element={<ResetPassword />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>

            <Route element={<PrivateRoute />}>
                <Route path="/profile" element={<Profile />}></Route>
            </Route>

            <Route element={<PrivateRoute />}>
                <Route element={<AdminLayout />}>
                    <Route element={<ProtectedRoute permissions="kp.view" />}>
                        <Route path="/admin/knowledge/preview" element={<PreviewKnowledge />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="kp.view" />}>
                        <Route path="/admin/knowledge/edit/:kmsid/:lang/:trid" element={<EditKnowledgePageWrapper />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="kp.view" />}>
                        <Route path="/admin/knowledge/edit/:kmsid" element={<EditKnowledgePageWrapper />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="kp.view" />}>
                        <Route path="/admin/knowledge" element={<Knowledge />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="apikey.view" />}>
                        <Route path="/admin/api-key" element={<ApiKey />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="mc.view" />}>
                        <Route path="/medical-conditions" element={<MedicalConditions />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="user.edit" />}>
                        <Route path="/users/add/:userid" element={<AddUser />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="user.view" />}>
                        <Route path="/users" element={<Users />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="infocus.view" />}>
                        <Route path="/in-focus-pages" element={<InFocus />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="infocus.edit" />} >
                        <Route path="/in-focus-pages-create" element={<CreateInFocus />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="infocus.edit" />}>
                        <Route path="/in-focus-pages-edit/:focusId" element={<CreateInFocus />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="infocus.edit" />}>
                        <Route path="/in-focus-pages-edit/:focusId/:lang/:trid" element={<CreateInFocus />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="drugs.view" />}>
                        <Route path="/drug-list" element={<DrugList />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="language.view" />}>
                        <Route path="/translations" element={<Translations />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="language.view" />}>
                        <Route path="/languages" element={<Languages />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="language.edit" />}>
                        <Route path="/add-translations/:trid" element={<AddTranslation />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="website.view" />}>
                        <Route path="/admin/website" element={<Website />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="website.edit" />}>
                        <Route path="/admin/website-add" element={<CreateNewPage />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="website.edit" />}>
                        <Route path="/admin/website-add/:id" element={<CreateNewPage />} />
                    </Route>
                    <Route element={<ProtectedRoute permissions="website.edit" />}>
                        <Route path="/admin/website-add/:id/:lang/:trid" element={<CreateNewPage />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
