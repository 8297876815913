import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import logout from "../../_actions/authentication/logout";
import { setKmsId, setLang, setTrId } from "../../_actions/kms.actions";

function PrivateRoute() {
  let user = JSON.parse(localStorage.getItem("user"));
  let location = useLocation();
  let dispatch = useDispatch();
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  let { kmsid, lang, trid } = useParams();
  useEffect(() => {
    if (kmsid) dispatch(setKmsId(kmsid));
    if (location.pathname !== "/admin/knowledge/preview") {
      dispatch(setLang(lang ? lang : "EN"));
      if (trid) dispatch(setTrId(trid));
    }
  }, [dispatch, kmsid, lang, location, trid, user]);
  if (user && user.token) return <Outlet />;
  else {
    dispatch(logout({}));
    return <Navigate to="/login" state={{ from: location }} />;
  }
}
export { PrivateRoute };
