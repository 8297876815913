import { commonConstants } from "../_constants";
import api from "../_helpers/mainapi";
import _ from "lodash";
import axios from "axios";

export function setLoading(status) {
    return { type: commonConstants.LOADING_PAGE, payload: status };
}

export const getPagingList = () => async (dispatch, getState) => {
    var pList = [
        { key: 10, text: 10, value: 10 },
        { key: 20, text: 20, value: 20 },
        { key: 50, text: 50, value: 50 },
        { key: 0, text: "All", value: 0 },
    ];
    dispatch({ type: commonConstants.GET_PAGING_LIST, payload: pList });
};

export const checkShowVersion = (pageDetails, idToCheck) => {
    const activePage = _.filter(pageDetails, (pd) => pd.active === true)[0];
    return idToCheck > 0 && (!activePage || activePage.id <= idToCheck);
}

export function getOtherVersions(pd) {
    const otherVersions = [];
    if (pd.lastConceptId !== pd.lastInReviewId && checkShowVersion([pd], pd.lastConceptId))
        otherVersions.push({
            id: pd.lastConceptId,
            name: "Concept",
            className: "concept",
            value: 1,
        });
    if (pd.lastInReviewId !== pd.lastPublishedId && checkShowVersion([pd], pd.lastInReviewId))
        otherVersions.push({
            id: pd.lastInReviewId,
            name: "In Review",
            className: "in-review",
            value: 2,
        });
    if (pd.lastPublishedId > 0)
        otherVersions.push({
            id: pd.lastPublishedId,
            name: "Published",
            className: "published",
            value: 3,
        });
    return otherVersions;
}

export const getStatusesList = () => async (dispatch, getState) => {
    await api.get(`/common/getallstatuses`).then((response) => {
        dispatch({
            type: commonConstants.GET_STATUSES_LIST,
            payload: _.map(response.data, (item) => ({
                key: item.id,
                text: item.name,
                value: item.id,
            })),
        });
    });
};

export const getCountries = (search) => async (dispatch, getState) => {
    await api.get(`/common/getallcountries?search=${search}`).then((response) => {
        if (response.status === 200) {
            var list = _.map(response.data, (country) => {
                return {
                    key: country.countryCode,
                    text: country.name,
                    value: country.countryCode,
                };
            });
            dispatch({ type: commonConstants.GET_COUNTRIES_LIST, payload: list });
        }
    });
};

export const getKeywordsTypeList = () => async (dispatch, getState) => {
    await api.get(`/values/getkeywordstype`).then((response) => {
        dispatch({
            type: commonConstants.GET_KEYWORDTYPE_LIST,
            payload: response.data,
        });
    });
};

export const getAtcsDispatch = (search) => async (dispatch, getState) => {
    await api.get(`/common/getallatcs`, { params: { search: search } })
        .then((response) => {
            if (response.status === 200) {
                var list = _.map(response.data, (atc) => {
                    return { key: atc.atc, text: atc.name, value: atc.atc };
                });
                dispatch({ type: commonConstants.GET_ATC_LIST, payload: list });
            }
        });
};

export const getDrugNames = (search) => async (dispatch, getState) => {
    await api.get(`/common/getalldrugnames?search=${search}`).then((response) => {
        if (response.status === 200) {
            var list = _.map(response.data, (d) => {
                return { key: d.id, text: d.name, value: d.name };
            });
            dispatch({ type: commonConstants.GET_DRUGNAMES_LIST, payload: list });
        }
    });
};

export const getSubstances = (search) => async (dispatch, getState) => {
    await api
        .get(`/common/getallsubstances?search=${search}`)
        .then((response) => {
            if (response.status === 200) {
                var list = _.map(response.data, (sub) => {
                    return {
                        key: sub.substanceId,
                        text: sub.name,
                        value: sub.substanceId,
                    };
                });
                dispatch({ type: commonConstants.GET_SUBSTANCE_LIST, payload: list });
            }
        });
};

export const getAllLanguages = () => async (dispatch, getState) => {
    await api
        .get(`/common/getalllanguages`)
        .then((response) => {
            dispatch({
                type: commonConstants.GETLANGUAGES_SUCCESS,
                payload: _.map(response.data, (item) => ({
                    key: item.id,
                    id: item.id,
                    text: item.name,
                    value: item.symbol,
                    label: item.name,
                })),
            });
        })
        .catch((error) => {
            dispatch({
                type: commonConstants.GETLANGUAGES_FAILURE,
                payload: "Error while loading languages",
            });
        });
};


export const getAllPageLanguages = () => async (dispatch, getState) => {
    await api.get(`/common/getallpagelanguages`).then((response) => {
        dispatch({
            type: commonConstants.GETPAGELANGUAGES_SUCCESS,
            payload: _.map(response.data, (item) => ({
                key: item.id,
                id: item.id,
                text: item.originalName,
                value: item.symbol,
                label: item.name,
            })),
        });
    }).catch((error) => {
        dispatch({
            type: commonConstants.GETPAGELANGUAGES_FAILURE,
            payload: "Error while loading languages",
        });
    });
};

export const getAllRoles = () => async (dispatch, getState) => {
    await api
        .get(`/common/getallroles`)
        .then((response) => {
            var resData = [{ id: 0, name: "" }, ...response.data];
            dispatch({
                type: commonConstants.GETROLES_SUCCESS,
                payload: _.map(resData, (item) => ({
                    key: item.id,
                    id: item.id,
                    text: item.name,
                    value: item.name,
                })),
            });
        })
        .catch((error) => {
            dispatch({
                type: commonConstants.GETROLES_FAILURE,
                payload: "Error while loading roles",
            });
        });
};

export function convertStringToDateFormat(date) {
    if (date && !isNaN(Date.parse(date))) {
        const dc = new Date(date);
        var [DD, MM, YYYY] = [
            dc.getDate().toString(),
            (dc.getMonth() + 1).toString(),
            dc.getFullYear().toString(),
        ];
        date = YYYY + "-" + MM.padStart(2, "0") + "-" + DD.padStart(2, "0");
    } else {
        date = "";
    }
    return date;
}
const monthNames = ["Јануари", "Февруари", "Март", "Април", "Мај", "Јуни", "Јули", "Август", "Септември", "Октомври", "Ноември", "Декември"];

export function convertStringToDateFormatMMYY(date, lang) {
    if (date && !isNaN(Date.parse(date))) {
        const dc = new Date(date);
        const monthName = lang === "MKD" ? monthNames[dc.getMonth() - 1] : dc.toLocaleString(lang, { month: 'long' });
        date = monthName + " " + dc.getFullYear().toString();
    } else {
        date = "";
    }
    return date;
}

export function convertStringToDateTimeFormat(date, format = null) {
    if (date && !isNaN(Date.parse(date))) {
        const dc = new Date(date);
        var [DD, MM, YYYY] = [
            dc.getDate().toString(),
            (dc.getMonth() + 1).toString(),
            dc.getFullYear().toString(),
        ];
        var [hh, mm, ss] = [
            dc.getHours().toString(),
            dc.getMinutes().toString(),
            dc.getSeconds().toString(),
        ];
        date = `${YYYY}-${MM.padStart(2, "0")}-${DD.padStart(2, "0")}`;
        if (format != null) {
            if (_.includes(format, "hh")) date += ` ${hh.padStart(2, "0")}`;
            if (_.includes(format, "mm")) date += `:${mm.padStart(2, "0")}`;
            if (_.includes(format, "ss")) date += `:${ss.padStart(2, "0")}`;
        } else
            date += ` ${hh.padStart(2, "0")}:${mm.padStart(2, "0")}:${ss.padStart(
                2,
                "0"
            )}`;
    } else {
        date = "";
    }
    return date;
}

export function stringCapitalWithSpace(stringCapital) {
    return _.join(_.split(stringCapital, /(?=[A-Z])/), " ");
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function orderByColumnsAudit(audits) {
    return _.orderBy(audits, (i) => [
        i.columnName === "PageId",
        i.columnName === "StatusesId",
        _.endsWith(i.columnName, "Summary"),
        _.endsWith(i.columnName, "Details"),
    ], ["desc", "desc", "desc", "desc"]);
}

export function getStatusColor(statusName) {
    switch (_.toLower(statusName)) {
        case "concept":
            return "";
        case "published":
            return " status--green";
        case "in review":
            return " status--blue";
        case "removed":
            return " status--red";
        default:
            return "";
    }
}

export function compare_lname(a, b) {
    return a.key.toLowerCase() < b.key.toLowerCase()
        ? -1
        : a.key.toLowerCase() > b.key.toLowerCase()
            ? 1
            : 0;
}

export const getPubMedIdData = (id) => async (_dispatch, _getState) => {
    // const response = await api.get("/common/getpubmed", {
    //   params: { id: id, puburl: "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi", db: "pubmed", retmode: "json" }
    // });
    const response = await axios.get("https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi", {
        params: { id: id, db: "pubmed", retmode: "json" }
    });

    var responseData = response.data.result[response.data.result.uids];
    if (!responseData || !_.isEmpty(responseData.error)) return null;

    var authors = _.take(responseData.authors, 3);
    var returnRef = `${responseData.title}.`;
    if (authors.length > 0)
        returnRef = _.join(_.map(authors, (a) => a.name), ", ") + `, ${returnRef}`;
    if (!_.isEmpty(responseData.fulljournalname))
        returnRef += ` ${responseData.fulljournalname}. `;
    if (!_.isEmpty(responseData.sortpubdate))
        returnRef += `${convertStringToDateFormat(responseData.sortpubdate)};`;
    if (!_.isEmpty(responseData.volume)) returnRef += `${responseData.volume}`;
    if (!_.isEmpty(responseData.issue)) returnRef += `(${responseData.volume})`;
    if (!_.isEmpty(responseData.pages)) returnRef += `:${responseData.pages}`;
    return returnRef;
};

export const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
}

export const getShortText = (text, length) => {
    const parsedHtml = new DOMParser().parseFromString(text, 'text/html');
    let returnText = "";
    let skipped = false;
    const onlyOne = parsedHtml.body.childNodes.length <= 1;
    if (onlyOne && parsedHtml.body.childNodes[0].innerText) {
        returnText += parsedHtml.body.childNodes[0].innerText.trim().substring(0, length);
    } else parsedHtml.body.childNodes.forEach(element => {
        if (skipped === false && element.innerText && (returnText.length + element.innerText.trim().length) < length)
            returnText += element.innerText.trim();
        else if (skipped === false && returnText.length === 0 && element.innerText)
            returnText += element.innerText.trim().substring(0, length);
        else skipped = true;
    });
    return returnText.concat('…');
}