import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { store } from "./_helpers";
import setupInterceptors from "./_helpers/setupInterceptors";
import "./scss/style.scss";
import "./scss/_print.scss";
// import './index.css';
//import App from './App';
import App from "./components/app";
import reportWebVitals from './reportWebVitals';
import {ReactNotifications} from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ReactNotifications />
      <App />
  </Provider>
);
setupInterceptors(store);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
