import React from "react";
import { ProgressBar, Puff } from "react-loader-spinner";

const Loading = ({
  visible,
  height = 100,
  width = 100,
  className = undefined,
  imiLoading = false,
  progressBar = false,
  centered = false,
}) => {
  return progressBar ? (
    <ProgressBar
      visible={visible}
      wrapperClass={className} />
  ) : imiLoading ? (
    <div style={{ display: visible ? "" : "none" }} className="loading style-2">
      <div className="loading-wheel"></div>
    </div>
  ) : centered ? (
    <div className="table-drug-list__load">
      <Puff
        visible={visible}
        color="#a2d7f3"
        height={height}
        width={width}
        wrapperClass={className}
      />
    </div>
  ) : (
    <Puff
      visible={visible}
      color="#a2d7f3"
      height={height}
      width={width}
      wrapperClass={className}
    />
  );
};

export default Loading;
