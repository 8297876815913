//import { history } from "../_helpers";
//import { alertActions } from "../_actions";
import axios from "axios";

export const useFetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }
    return axios(url, requestOptions);//.then(handleResponse);
  };
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  if (!localStorage.getItem("user"))
    return {};

  let user = JSON.parse(localStorage.getItem("user"));
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  if (user && user.token && isApiUrl) {
    return { Authorization: `Bearer ${user.token}` };
  }
  else {
    return {};
  }
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (!response.ok) {
//       if ([401, 403].includes(response.status)) {
//         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
//         localStorage.removeItem("user");
//         history.push("/login");
//       }

//       const error = (data && data.message) || response.statusText;
//       alertActions.error(error);
//       return Promise.reject(error);
//     }

//     return data;
//   });
// }
