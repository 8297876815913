import axios from "axios";
import axiosInstance, { clearInstance } from "./mainapi";
import logout from "../_actions/authentication/logout";
import { history } from "./";
import TokenService from "../_services/token.service";

const configToken = (config) => {
  const token = TokenService.getLocalAccessToken();
  if (token)
    config.headers.Authorization = `Bearer ${token}`;
  return config;
}

const responeErrorInterceptor = async (er, dispatch) => {
  if (axios.isAxiosError(er)) {
    const originalRequest = er.config;
    if (er.response) {
      //AccessToken was expired
      const refToken = TokenService.getLocalRefreshToken();
      if (er.response.status === 401 || er.response.status === 403) {
        if (er.response.status === 401 && !originalRequest._retry && refToken) {
          originalRequest._retry = true;
          try {
            const rs = await axiosInstance.post("/token/refresh", { token: refToken });
            const { token, refreshToken } = rs.data;
            TokenService.updateLocalTokens(token, refreshToken);
            return axiosInstance(originalRequest);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else if (window.location.pathname.includes("form")) history.replace("/");
        else {
          dispatch(logout({}));
          history.replace("/login");
        }
        // <-- navigate
      } else if (!er.request.responseURL.includes("token/checkvcode") && (er.response.status === 404 || er.response.status === 503))
        history.replace("/notfound"); // <-- navigate to not found page
      else if (er.response.status === 400)
        history.replace("/error"); // <-- navigate to error page
    }
  }
  return Promise.reject(er);
}

const setup = (store) => {
  axiosInstance.interceptors.request.use((config) => configToken(config));
  clearInstance.interceptors.request.use((config) => configToken(config));

  const { dispatch } = store;
  axiosInstance.interceptors.response.use((response) => response, (er) => responeErrorInterceptor(er, dispatch));
  clearInstance.interceptors.response.use((response) => response, (er) => responeErrorInterceptor(er, dispatch));
};

export default setup;
