import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { RouteProps } from 'react-router';
import usePermissions from "../../customHooks/usePermissions";

// export interface ProtectedRouteProps extends RouteProps {
//   isAuthenticated: boolean;
//   isAllowed: boolean;
//   restrictedPath: string;
//   authenticationPath: string;
// }

export const ProtectedRoute = props => {

   const hasPermissions = usePermissions(props.permissions);

   if (!hasPermissions) {
      return <Navigate to="/admin/knowledge" />
   }

   return <Outlet />;

};

export default ProtectedRoute;