import { drugConstants, importDataConstants } from "../_constants";

export function drugs(state = {}, action) {
  switch (action.type) {
    case drugConstants.GET_TABLE_DRUGS:
      return action.payload;
    default:
      return state;
  }
}

export function importData(state = [], action) {
  switch (action.type) {
    case importDataConstants.GET_IMPORT_INFO:
      return action.payload;
    case importDataConstants.SET_IMPORT_STEP:
      return !action.payload
        ? state
        : state.map(i => i.step === action.payload.step ? action.payload : i);
    case importDataConstants.START_IMPORTING:
      return state.map(i => {
        return { ...i, state: null }
      });
    default: return state;
  }
}
